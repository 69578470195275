<template>
  <div class="train-video">
    <div class="videoTitle">培训视频</div>
    <div class="row videoMain">
      <!-- <div
        class="fl videoMain"
        v-for="(item, i) in list"
        :key="i"
        style="width: 30%"
      >
        <video
          :id="'myVideo' + item.id"
          class="video-js"
          poster="../../assets/images/1.jpg"
        >
          <source :src="item.src" type="video/mp4" />
        </video>
        <div class="video-name">中山市恒丰利织造有限公司</div>
      </div> -->

      <div
        class="col col-3"
        v-for="(item, index) in videoLists"
        :key="item.index"
      >
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions[index]"
          @play="onPlayerPlay($event, index)"
          @pause="onPlayerPause($event)"
        ></video-player>
        <div class="video-name" :title="item.name">{{item.name}}</div>
      </div>

      <!-- <div class="videoMain">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="videoPlayerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
        ></video-player>
      </div> -->
    </div>
  </div>
</template>

<script>
import "../../assets/css/trainvideo.less";
import "../../assets/css/adminhome.less";
import "../../assets/css/dashboard.less";

import { videoPlayer } from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

export default {
  components: {
    videoPlayer,
  },
  data() {
    return {
      // video: "", //具体视频
      // fileType: "mp4", // 资源的类型
      // videoUrl: "", // 资源的路径地址
      // posterUrl: "", //封面地址

      videoLists: [],
      playerOptions: [],
      currentPlayer: ""
    };
  },

  created() {
    this.getVideoList();
  },

  methods: {
    getVideoList() {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingTalk/VideoLists.ashx",
        params: {
          ctype: "getVideoList",
        },
      })
        .then((res) => {
          this.videoLists = res.data.data;
          for (var i = 0; i < this.videoLists.length; i++) {
            let arrs = {
              playbackRates: [0.5, 0.75, 1.0, 1.25, 1.5, 2.0], //播放速度
              autoplay: false, //阻止回放
              muted: false, //消除任何音频
              loop: false, //视频结束重新开始
              preload: "auto", //遇<video>立即加载视频
              language: "zh-CN",
              aspectRatio: "16:9", //播放器处于流畅模式
              fluid: true, //自适应
              sources: [
                {
                  type: "video/mp4",
                  type: "video/ogg",
                  src: "/video/" + this.videoLists[i].address,
                },
              ],
              poster: require("../../assets/images/1.jpg"), //封面地址
              notSupportedMessage: "此视频暂无法播放，请稍后再试",
              controlBar: {
                timeDivider: true, //进度条
                durationDisplay: true,
                remainingTimeDisplay: false,
                fullscreenToggle: true, //全屏按钮
              },
            };
            this.playerOptions.push(arrs);
          }
        })
        .catch((err) => {});
    },

    // 播放回调
    onPlayerPlay(player, index) {

      var that = this.$refs.videoPlayer;

      for(var i = 0; i < that.length; i++){
        if(i != index){
          that[i].player.pause();
        }
      }

      // // 只允许一条视频播放
      // if(this.currentPlayer){
      //   this.currentPlayer.pause();
      // }
      // this.currentPlayer = player;


    },

    // 暂停回调
    onPlayerPause(player) {
    },
  },

  // computed: {
  //   videoPlayerOptions() {
  //     const videoPlayerOptions = {
  //       playbackRates: [0.5, 1.0, 1.25, 1.5, 2.0],
  //       autoplay: false,
  //       muted: false,
  //       loop: false,
  //       preload: "auto",
  //       language: "zh-CN",
  //       aspectRatio: "3:1",
  //       fluid: true,
  //       flash: { hls: { withCreadentials: false } },
  //       html5: { hls: { withCreadentials: false } },
  //       sources: [
  //         {
  //           type: "video/" + this.fileType,
  //           src: "http://img.yopoo.cn/banner_video.mp4", // 视频url地址
  //         },
  //       ],
  //       poster: "../../assets/images/1.jpg", // 封面地址
  //       width: "100%",
  //       notSupportedMessage: "此视频暂无法播放...",
  //       controlBar: {
  //         timeDivider: true,
  //         durationDisplay: true,
  //         remainingTimeDisplay: false,
  //         fullscreenToggle: true,
  //       },
  //     };
  //     return videoPlayerOptions;
  //   },
  // },

  // data() {
  //   return {
  //     // list: [
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 0,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 1,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 2,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 3,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 4,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 5,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 6,
  //     //     pic: "../../assets/images/logo02.png",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 7,
  //     //     pic: "",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 8,
  //     //     pic: "",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 9,
  //     //     pic: "",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 10,
  //     //     pic: "",
  //     //   },
  //     //   {
  //     //     src: "http://img.yopoo.cn/banner_video.mp4 ",
  //     //     id: 11,
  //     //     pic: "",
  //     //   },
  //     // ],
  //   };
  // },
  // mounted() {
  //   // this.initVideo();
  // },
  // methods: {
  //   // initVideo() {
  //   //   //初始化视频方法 循环列表获取每个视频的id
  //   //   this.list.map((item, i) => {
  //   //     let myPlayer = this.$video("myVideo" + item.id, {
  //   //       //确定播放器是否具有用户可以与之交互的控件
  //   //       controls: true,
  //   //       //自动播放属性,muted:静音播放
  //   //       autoplay: false,
  //   //       // 播放速度
  //   //       playbackRates: [0.5, 1.0, 1.5, 2.0],
  //   //       // 静音播放
  //   //       muted: "mutec",
  //   //       // 循环播放
  //   //       loop: false,
  //   //       //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
  //   //       preload: "auto",
  //   //       // 比例缩放适应容器
  //   //       fluid: true,
  //   //       // //设置视频播放器的显示宽度（以像素为单位）
  //   //       // width: "260px",
  //   //       // //设置视频播放器的显示高度（以像素为单位）
  //   //       // height: "180px",
  //   //       //封面图
  //   //       // poster: "../../assets/images/logo02.png",
  //   //     });
  //   //   });
  //   // },
  // },
};
</script>

<style lang="scss" scoped>

</style>